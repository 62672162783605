import db from "../firebase";
import { collection, addDoc, updateDoc, getDocs, getDoc, doc, deleteDoc } from "firebase/firestore"; 

class PescariDataService {
  async getConcursuri() {
    const results = [];

    const querySnapshot = await getDocs(collection(db, "concursuri"));
    querySnapshot.forEach((doc) => {
        results.push({id:doc.id, ...doc.data() });
    });

    //order by "creat_la"
    results.sort((a,b) => b.concurs.creat_la.seconds - a.concurs.creat_la.seconds);

    return results;
  }
  async getScoruri(documentID){
    let results = [];
  
    const querySnapshot = await getDocs(collection(db, "concursuri",documentID,"echipe"));
    querySnapshot.forEach((doc) => {
        results.push({id:doc.id, ...doc.data() });
    });

    return results;
  }

  async getEchipa(IDconcurs, IDechipa){
    const docRef = doc(db, "concursuri", IDconcurs, "echipe", IDechipa);
    const docSnap = await getDoc(docRef);

    const results = null;
    if(docSnap.exists()){
      return  docSnap.data();
    }
    return results;
  }
  async getConcurs(documentID){
    const docRef = doc(db, "concursuri", documentID);
    const docSnap = await getDoc(docRef);

    const results = null;
    if(docSnap.exists()){
      return  docSnap.data();
    }
    return results;

  }
  async getInregistrariEchipa(documentID, echipaID){
    const results = [];

    const querySnapshot = await getDocs(collection(db, "concursuri",documentID,"echipe", echipaID, "inregistrari"));
    querySnapshot.forEach((doc) => {
        results.push({id:doc.id, ...doc.data() });
        results.sort((a,b) => b.adaugat_la.seconds - a.adaugat_la.seconds);
    });
    return results;
  }
  async getEchipe(documentID) {
    const results = [];

    await getDocs(collection(db, "concursuri",documentID,"echipe")).then( resultsMain => {
      
      resultsMain.forEach((doc) => {
        results.push({id:doc.id, ...doc.data() });
      })
      return results;
    });

    // console.log(querySnapshot);
    // querySnapshot.forEach((doc) => {
    //     results.push({id:doc.id, ...doc.data() });
    // })
    return results;
  }

  async addGreutateEchipa(documentID, idEchipa, greutate){
    let data = {
      greutate: greutate,
      adaugat_la:  new Date()
    };

    try{
      const docRef = await addDoc(collection(db,"concursuri",documentID, "echipe", idEchipa, "inregistrari"),
        data
      );
      console.log("Document written with ID: ", docRef.id);
    } catch(e){
      console.error("Error adding document: ", e);
    }
    
  }
  async modificaEchipa(documentID, data){
    try{
      console.log(data);
      const echipaRef = doc(db, "concursuri", documentID, "echipe", data.id);
      await updateDoc(echipaRef, {
        echipa:{
          nume: data.nume,
          sector: data.sector,
          stand: data.stand
        }
     
      });
    } catch(e){
      console.error("Error adding document: ", e);
    }
  }
  async addEchipa(documentID, echipa){
    try{
      const docRef = await addDoc(collection(db,"concursuri",documentID, "echipe"),{
        echipa
      });
      console.log("Document written with ID: ", docRef.id);
    } catch(e){
      console.error("Error adding document: ", e);
    }
  }
  async createConcurs(concurs) {
    concurs.creat_la = new Date();
    try {
      const docRef = await addDoc(collection(db, "concursuri"), {
        concurs
      });
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  }
  async deleteInregistrare(inregistrareID, echipaID, concursID){
    try {
      await deleteDoc( doc(db, "concursuri", concursID, "echipe", echipaID, "inregistrari", inregistrareID ));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  }

  async deleteEchipa(echipaID, concursID){
    try {
      await deleteDoc( doc(db, "concursuri", concursID, "echipe", echipaID));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  }
  async deleteConcurs(concursID){
    try {
      await deleteDoc( doc(db, "concursuri", concursID));
    } catch (e) {
      console.error("Error deleting document: ", e);
    }
  }
}
export default new PescariDataService();