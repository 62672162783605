import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAAwSbpaaeaxlNdpcM-AWNjnY3t8ncBnD4",
  authDomain: "pescari-b1ee0.firebaseapp.com",
  projectId: "pescari-b1ee0",
  storageBucket: "pescari-b1ee0.appspot.com",
  messagingSenderId: "258005661970",
  appId: "1:258005661970:web:19666be593f7618ea75bcb",
  measurementId: "G-YH7NRD9KSF"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default db;